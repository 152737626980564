import { lazy } from 'react';
import Loadable from './Loadable';


// Landing Page
const LandingPage = Loadable(lazy(() => import('../pages/home/LandingPage')));
const ContactUsPage = Loadable(lazy(()=> import('../pages/home/ContactUsPage')));

export const routes = () => {
  return [
    // Landing Page
    {  path: '/', element: <LandingPage /> },
    {  path: '/contact-us', element: <ContactUsPage /> },
    

  ];
};